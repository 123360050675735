.m-alert {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100%;
  max-width: 400px;
  z-index: 4; }
  .m-alert__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .m-alert__item {
    position: relative;
    padding: 20px;
    padding-left: 26px;
    margin-top: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow: hidden; }
    .m-alert__item.centralized {
      position: fixed;
      top: 50% !important;
      left: 50% !important;
      margin-right: -50%;
      transform: translate(-50%, -50%); }
    .m-alert__item:before {
      display: block;
      content: '';
      width: 6px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #4fc8d5; }
    .m-alert__item-title {
      display: block;
      font-size: 22px;
      font-size: 2.2rem;
      font-weight: 700;
      color: #4fc8d5; }
    .m-alert__item p {
      line-height: 1.4; }
    .m-alert__item.success .m-alert__item-title {
      color: #28a745; }
    .m-alert__item.success:before {
      background-color: #28a745; }
    .m-alert__item.warning .m-alert__item-title {
      color: #ffc107; }
    .m-alert__item.warning:before {
      background-color: #ffc107; }
    .m-alert__item.danger .m-alert__item-title {
      color: #dc3545; }
    .m-alert__item.danger:before {
      background-color: #dc3545; }
    .m-alert__item.default {
      animation: fadeIn 300ms; }
      .m-alert__item.default.fast {
        animation: fadeIn 300ms, fadeOut 300ms 5000ms forwards; }
    .m-alert__item.shake {
      animation: fadeIn 300ms, shake 600ms; }
      .m-alert__item.shake.fast {
        animation: fadeIn 300ms, shake 600ms, fadeOut 300ms 5000ms forwards; }
  .m-alert__button {
    position: absolute;
    top: 0;
    right: 0; }
