.a-slider {
  width: 100%;
  height: 16px;
  padding-right: 16px;
  position: relative;
  cursor: pointer; }
  .a-slider::before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2px; }
  .a-slider__active {
    display: block;
    position: relative;
    height: 16px;
    z-index: 1; }
    .a-slider__active:before {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background-color: #FFF;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 2px 0 0 2px; }
    .a-slider__active:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background-color: #FFF;
      box-shadow: 0 0 10px black;
      position: absolute;
      right: -16px; }
  .a-slider__buffer {
    display: block;
    height: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 2px;
    transition: width 900ms; }
  .a-slider__maskDrag {
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; }
