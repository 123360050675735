.m-formGroup {
  position: relative; }
  .m-formGroup__label {
    display: block;
    margin-bottom: 10px;
    margin-left: 22px;
    font-weight: bold; }
    .m-formGroup.negative .m-formGroup__label {
      color: #FFF; }
    .m-formGroup.danger .m-formGroup__label {
      color: #dc3545; }
  .m-formGroup__legend {
    display: block;
    margin-top: 5px;
    margin-left: 22px;
    font-size: 12px;
    font-size: 1.2rem;
    font-style: italic; }
    .m-formGroup.danger .m-formGroup__legend {
      color: #dc3545; }
  .m-formGroup__globalFormField {
    position: relative; }
  .m-formGroup__formField, .Select-control {
    display: block;
    width: 100%;
    border: 2px solid #e6e6e6;
    padding: 15px 20px;
    border-radius: 25px;
    color: #8c8c8c;
    transition: border 300ms; }
    .m-formGroup.negative .m-formGroup__formField, .m-formGroup.negative .Select-control {
      background-color: transparent;
      color: #FFF; }
    .m-formGroup.danger .m-formGroup__formField, .m-formGroup.danger .Select-control {
      border-color: #dc3545; }
    .m-formGroup.focus .m-formGroup__formField, .m-formGroup.focus .Select-control {
      border-color: #5fcdd9; }
    .m-formGroup__icon + .m-formGroup__formField, .m-formGroup__icon + .Select-control {
      padding-left: 46px; }
  .m-formGroup__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    color: #d9d9d9;
    transition: color 300ms; }
    .m-formGroup.focus .m-formGroup__icon {
      color: #4fc8d5; }

.Select-control {
  cursor: text;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 45px;
  background-color: #FFFFFF; }
  .Select-control input {
    color: #8c8c8c;
    background-color: transparent; }
    .m-formGroup.negative .Select-control input {
      color: #FFF; }
  .is-open .Select-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .is-focused .Select-control {
    border-color: #5fcdd9; }
  .has-value .Select-control {
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 65px;
    padding-left: 10px; }

.Select-placeholder {
  position: absolute; }

.Select-aria-only {
  display: none; }

.Select-value {
  display: inline-block; }

.Select-clear-zone {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 45px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #d9d9d9;
  font-size: 20px;
  font-size: 2rem;
  cursor: pointer; }

.Select-arrow-zone {
  display: none; }

.Select-arrow-down {
  left: inherit;
  right: 20px;
  cursor: pointer; }

.Select-menu-outer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-top: 0;
  background-color: #e6e6e6;
  z-index: 1; }
  .border .Select-menu-outer {
    border: 2px solid #cccccc !important;
    border-top: 0 !important; }
  .Select-menu-outer .VirtualizedSelectOption, .Select-menu-outer .Select-noresults {
    line-height: 35px;
    padding-left: 20px;
    padding-right: 20px;
    color: #8c8c8c;
    cursor: pointer; }
    .Select-menu-outer .VirtualizedSelectOption.VirtualizedSelectFocusedOption, .Select-menu-outer .VirtualizedSelectFocusedOption.Select-noresults {
      background-color: #4a90e2;
      color: #FFF; }
    .m-formGroup.negative .Select-menu-outer .VirtualizedSelectOption.VirtualizedSelectFocusedOption, .m-formGroup.negative .Select-menu-outer .VirtualizedSelectFocusedOption.Select-noresults {
      background-color: #4fc8d5; }
  .Select-menu-outer .Select-noresults {
    cursor: default; }

.Select-value {
  padding-right: 10px;
  height: 30px;
  font-size: 12px;
  font-size: 1.2rem;
  background-color: #4a90e2;
  color: #FFF;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px; }
  .Select--single .Select-value {
    line-height: 30px;
    padding-left: 10px; }
  .m-formGroup.negative .Select-value {
    background-color: #4fc8d5; }
  .Select-value-icon {
    display: inline-block;
    width: 25px;
    height: 100%;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-size: 1.4rem; }
    .Select-value-icon:hover {
      opacity: .6; }
