.a-fieldEditable {
  position: relative;
  display: inline-block;
  line-height: inherit; }
  .a-fieldEditable.active:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -5px;
    left: -5px;
    padding: 3px;
    padding-right: 20px;
    box-sizing: initial;
    border: 2px solid #ebebeb;
    border-radius: 15px; }
  .a-fieldEditable.active.text:before {
    cursor: text; }
  .a-fieldEditable__icon {
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
    .a-fieldEditable.primary .a-fieldEditable__icon {
      color: #a6a6a6; }
    .a-fieldEditable.negative .a-fieldEditable__icon {
      color: #FFF; }
