.p-categoryPage__updateCustomField-modalWithoutScroll .m-modal__box {
  overflow: inherit; }

.p-categoryPage__legend {
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 12px;
  font-size: 1.2rem;
  color: #d32f2f; }
  .p-categoryPage__legend-icon {
    margin-right: 5px;
    transform: scaleX(-1); }
