.m-poweredBy {
  color: gray;
  font-size: 12px;
  font-size: 1.2rem; }
  .m-poweredBy__logo {
    max-height: 24px;
    vertical-align: middle;
    opacity: .3;
    filter: grayscale(100%);
    transition: opacity 300ms, filter 300ms; }
    .m-poweredBy__logo:hover {
      opacity: 1;
      filter: grayscale(0%); }
