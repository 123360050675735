.o-searchHeader-disabled {
  pointer-events: none; }

.o-searchHeader {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  min-height: 50px;
  background-color: #e6e6e6;
  display: flex;
  position: absolute;
  z-index: 1; }
  .o-searchHeader .left {
    display: flex;
    align-items: center; }
    .o-searchHeader .left .clear {
      display: flex;
      align-items: center;
      font-weight: bold; }
      .o-searchHeader .left .clear:hover {
        cursor: pointer; }
      .o-searchHeader .left .clear .text {
        margin-left: 5px;
        font-size: 12px;
        font-size: 1.2rem; }
