[class^='col-'] {
  flex: 0 0 auto;
  padding-right: 10px;
  padding-left: 10px;
  transition: all 900ms ease-in-out; }
  .noGutter [class^='col-'] {
    padding-right: 0;
    padding-left: 0; }

.col-xs-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.colOffset-xs-0 {
  margin-left: 0; }

.colOffset-xs-1 {
  margin-left: 8.33333%; }

.colOffset-xs-2 {
  margin-left: 16.66667%; }

.colOffset-xs-3 {
  margin-left: 25%; }

.colOffset-xs-4 {
  margin-left: 33.33333%; }

.colOffset-xs-5 {
  margin-left: 41.66667%; }

.colOffset-xs-6 {
  margin-left: 50%; }

.colOffset-xs-7 {
  margin-left: 58.33333%; }

.colOffset-xs-8 {
  margin-left: 66.66667%; }

.colOffset-xs-9 {
  margin-left: 75%; }

.colOffset-xs-10 {
  margin-left: 83.33333%; }

.colOffset-xs-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 576px) {
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 576px) {
  .col-sm-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 576px) {
  .col-sm-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 576px) {
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 576px) {
  .col-sm-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 576px) {
  .col-sm-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 576px) {
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 576px) {
  .col-sm-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 576px) {
  .col-sm-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .colOffset-sm-0 {
    margin-left: 0; } }

@media (min-width: 576px) {
  .colOffset-sm-1 {
    margin-left: 8.33333%; } }

@media (min-width: 576px) {
  .colOffset-sm-2 {
    margin-left: 16.66667%; } }

@media (min-width: 576px) {
  .colOffset-sm-3 {
    margin-left: 25%; } }

@media (min-width: 576px) {
  .colOffset-sm-4 {
    margin-left: 33.33333%; } }

@media (min-width: 576px) {
  .colOffset-sm-5 {
    margin-left: 41.66667%; } }

@media (min-width: 576px) {
  .colOffset-sm-6 {
    margin-left: 50%; } }

@media (min-width: 576px) {
  .colOffset-sm-7 {
    margin-left: 58.33333%; } }

@media (min-width: 576px) {
  .colOffset-sm-8 {
    margin-left: 66.66667%; } }

@media (min-width: 576px) {
  .colOffset-sm-9 {
    margin-left: 75%; } }

@media (min-width: 576px) {
  .colOffset-sm-10 {
    margin-left: 83.33333%; } }

@media (min-width: 576px) {
  .colOffset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 768px) {
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 768px) {
  .col-md-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 768px) {
  .col-md-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 768px) {
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 768px) {
  .col-md-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 768px) {
  .col-md-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 768px) {
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 768px) {
  .col-md-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 768px) {
  .col-md-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 768px) {
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .colOffset-md-0 {
    margin-left: 0; } }

@media (min-width: 768px) {
  .colOffset-md-1 {
    margin-left: 8.33333%; } }

@media (min-width: 768px) {
  .colOffset-md-2 {
    margin-left: 16.66667%; } }

@media (min-width: 768px) {
  .colOffset-md-3 {
    margin-left: 25%; } }

@media (min-width: 768px) {
  .colOffset-md-4 {
    margin-left: 33.33333%; } }

@media (min-width: 768px) {
  .colOffset-md-5 {
    margin-left: 41.66667%; } }

@media (min-width: 768px) {
  .colOffset-md-6 {
    margin-left: 50%; } }

@media (min-width: 768px) {
  .colOffset-md-7 {
    margin-left: 58.33333%; } }

@media (min-width: 768px) {
  .colOffset-md-8 {
    margin-left: 66.66667%; } }

@media (min-width: 768px) {
  .colOffset-md-9 {
    margin-left: 75%; } }

@media (min-width: 768px) {
  .colOffset-md-10 {
    margin-left: 83.33333%; } }

@media (min-width: 768px) {
  .colOffset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1024px) {
  .col-lg-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 1024px) {
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 1024px) {
  .col-lg-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1024px) {
  .col-lg-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1024px) {
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 1024px) {
  .col-lg-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 1024px) {
  .col-lg-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1024px) {
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 1024px) {
  .col-lg-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 1024px) {
  .col-lg-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .colOffset-lg-0 {
    margin-left: 0; } }

@media (min-width: 1024px) {
  .colOffset-lg-1 {
    margin-left: 8.33333%; } }

@media (min-width: 1024px) {
  .colOffset-lg-2 {
    margin-left: 16.66667%; } }

@media (min-width: 1024px) {
  .colOffset-lg-3 {
    margin-left: 25%; } }

@media (min-width: 1024px) {
  .colOffset-lg-4 {
    margin-left: 33.33333%; } }

@media (min-width: 1024px) {
  .colOffset-lg-5 {
    margin-left: 41.66667%; } }

@media (min-width: 1024px) {
  .colOffset-lg-6 {
    margin-left: 50%; } }

@media (min-width: 1024px) {
  .colOffset-lg-7 {
    margin-left: 58.33333%; } }

@media (min-width: 1024px) {
  .colOffset-lg-8 {
    margin-left: 66.66667%; } }

@media (min-width: 1024px) {
  .colOffset-lg-9 {
    margin-left: 75%; } }

@media (min-width: 1024px) {
  .colOffset-lg-10 {
    margin-left: 83.33333%; } }

@media (min-width: 1024px) {
  .colOffset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xlg-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  .col-xlg-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 1280px) {
  .col-xlg-3 {
    flex-basis: 25%;
    max-width: 25%; } }

@media (min-width: 1280px) {
  .col-xlg-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1280px) {
  .col-xlg-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1280px) {
  .col-xlg-6 {
    flex-basis: 50%;
    max-width: 50%; } }

@media (min-width: 1280px) {
  .col-xlg-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 1280px) {
  .col-xlg-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1280px) {
  .col-xlg-9 {
    flex-basis: 75%;
    max-width: 75%; } }

@media (min-width: 1280px) {
  .col-xlg-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 1280px) {
  .col-xlg-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xlg-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-0 {
    margin-left: 0; } }

@media (min-width: 1280px) {
  .colOffset-xlg-1 {
    margin-left: 8.33333%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-2 {
    margin-left: 16.66667%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-3 {
    margin-left: 25%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-4 {
    margin-left: 33.33333%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-5 {
    margin-left: 41.66667%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-6 {
    margin-left: 50%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-7 {
    margin-left: 58.33333%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-8 {
    margin-left: 66.66667%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-9 {
    margin-left: 75%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-10 {
    margin-left: 83.33333%; } }

@media (min-width: 1280px) {
  .colOffset-xlg-11 {
    margin-left: 91.66667%; } }
