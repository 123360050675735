.a-newTimestamp {
  border: 2px solid #ebebeb;
  background-color: #FFF; }
  .a-newTimestamp__target {
    display: flex;
    align-items: center;
    transition: color 300ms;
    cursor: pointer;
    padding: 2.5px 5px;
    min-height: 30px;
    transition: background-color 300ms; }
    .a-newTimestamp__target:hover {
      background-color: #ebebeb; }
    .a-newTimestamp__target.active {
      background-color: #4fc8d5;
      color: #FFFFFF; }
    .a-newTimestamp__target-icon {
      margin-right: 5px; }
    .a-newTimestamp__target-iconExpand {
      transition: transform 300ms; }
      .a-newTimestamp.active .a-newTimestamp__target-iconExpand {
        transform: rotate(180deg); }
    .a-newTimestamp__target-label {
      line-height: 1.4; }
    .a-newTimestamp__target-badge {
      display: inline-flex;
      justify-content: center;
      text-align: center;
      padding: 0 2.5px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      margin-left: auto;
      font-size: 12px;
      font-size: 1.2rem;
      background-color: #4fc8d5;
      color: #FFFFFF;
      transition: background-color 300ms, color 300ms; }
      .a-newTimestamp.active .a-newTimestamp__target-badge {
        background-color: #b3b3b3;
        color: #FFF; }
  .a-newTimestamp__labelComplement {
    color: #b3b3b3;
    font-size: 12px;
    font-size: 1.2rem; }
  .a-newTimestamp__time {
    display: inline-block;
    color: #4fc8d5;
    border-radius: 10px;
    transition: background 300ms;
    margin-left: auto;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 700; }
    .a-newTimestamp__target.active .a-newTimestamp__time, .a-newTimestamp__dropdown-item.active .a-newTimestamp__time {
      color: #FFFFFF; }
  .a-newTimestamp__dropdown {
    max-height: 0;
    transition: max-height 300ms;
    overflow: hidden; }
    .a-newTimestamp.inactive .a-newTimestamp__dropdown {
      max-height: 0 !important; }
    .a-newTimestamp__dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 2.5px 5px;
      min-height: 30px;
      transition: background-color 300ms; }
      .a-newTimestamp__dropdown-item:hover {
        background-color: #ebebeb; }
      .a-newTimestamp__dropdown-item.active {
        background-color: #4fc8d5;
        color: #FFFFFF; }
    .a-newTimestamp__dropdown-icon {
      width: 20px !important;
      margin-right: 5px;
      text-align: right;
      opacity: 0.5; }
