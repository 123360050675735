.o-tagDetails__header {
  background-color: #4fc8d5; }
  .o-tagDetails__header-title {
    font-size: 26px;
    font-size: 2.6rem;
    color: #FFF;
    font-weight: 700; }
  .o-tagDetails__header p {
    color: #FFF; }

.o-tagDetails__formGroupName input {
  font-weight: 700;
  font-size: 22px;
  font-size: 2.2rem; }
