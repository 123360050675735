.a-player {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #999999;
  text-align: initial;
  min-height: 400px; }
  .a-player.audio {
    background-color: #000; }
  .a-player__media {
    display: block; }
  .a-player__inMiddle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .a-player__maskOnMedia {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1; }
    .a-player__maskOnMedia-overlay {
      background-color: rgba(0, 0, 0, 0.5); }
    .a-player__maskOnMedia-icon {
      font-size: 80px !important;
      font-size: 8rem !important;
      width: 80px !important;
      height: 80px !important;
      color: #FFF !important; }
      .a-player__maskOnMedia-icon.isAnimated {
        animation: pressed 900ms linear forwards; }
  .a-player__nav {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 40px 20px 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    z-index: 1;
    transform: translateY(100%);
    transition: transform 900ms; }
    .a-player__nav.show {
      transform: translateY(0%); }
    .a-player__nav-section {
      display: flex;
      align-items: center; }
    .a-player__nav-action {
      padding: 10px;
      box-sizing: initial;
      cursor: pointer; }
  .a-player__settings {
    transition: transform 300ms; }
    .a-player__settings.spin {
      transform: rotate(30deg); }
  .a-player__changeQuality {
    position: absolute;
    bottom: calc(100% - (40px - 10px));
    right: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    padding: 10px 10px 0;
    color: #FFF; }
    .a-player__changeQuality.show {
      animation: fadeIn 300ms forwards; }
    .a-player__changeQuality.hide {
      animation: fadeOut 300ms forwards; }
    .a-player__changeQuality-title {
      display: block;
      margin-bottom: 5px;
      font-size: 12px;
      font-size: 1.2rem;
      font-weight: 700; }
    .a-player__changeQuality li {
      text-align: right;
      padding: 10px 0;
      min-height: 20px;
      line-height: 20px;
      box-sizing: initial;
      cursor: pointer; }
    .a-player__changeQuality-icon {
      float: left; }
  .a-player__actionsOnRight {
    margin-left: auto; }
  .a-player__icon {
    color: #FFF;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5); }
  .a-player__volume {
    width: 100px; }
  .a-player__time {
    color: #cccccc;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-size: 1.2rem; }
  .a-player__audioCallback {
    opacity: 0; }
    .a-player__audioCallback.active {
      animation: fadeIn 900ms forwards; }

@keyframes pressed {
  from {
    transform: scale(0.5);
    opacity: 1; }
  to {
    transform: scale(1.5);
    opacity: 0; } }
