.o-assetModal__title {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 20px; }

.o-assetModal__assetPreview {
  position: relative;
  width: 100%;
  border-bottom: 2px solid #ebebeb;
  text-align: center; }
  .o-assetModal__assetPreview-globalSquare {
    display: inline-block;
    position: relative; }
  .o-assetModal__assetPreview video, .o-assetModal__assetPreview audio {
    width: 100%; }

.o-assetModal__image {
  display: block;
  max-width: 100%;
  max-height: 640px;
  margin: 0 auto; }

.o-assetModal__iaActions {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 1; }
  .o-assetModal__iaActions li {
    text-align: right;
    margin-bottom: 5px; }
  .o-assetModal__iaActions-icon {
    padding: 5px;
    box-sizing: initial;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #cdcdcd;
    border-radius: 6px 0 0 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
    transition: padding 300ms; }
    .o-assetModal__iaActions-icon.active {
      color: #4a90e2;
      transition: padding 300ms, background 300ms, color 300ms; }
    .o-assetModal__iaActions-icon:hover {
      padding-right: 10px;
      color: #4a90e2; }

.o-assetModal__iaView {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 40px 20px;
  background-color: #FFFFFF;
  transition: left 300ms, background 300ms;
  z-index: 1; }
  .o-assetModal__iaView.active {
    left: 0;
    background-color: #e6e6e6; }
  .o-assetModal__iaView-title {
    display: block;
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 10px;
    font-weight: 700; }

.o-assetModal__timestamp {
  border-color: #d1d1d1; }

.o-assetModal__globalAssetInfos {
  position: relative;
  height: 100%;
  border-left: 2px solid #ebebeb;
  overflow: hidden; }
  .o-assetModal__globalAssetInfos p {
    margin-bottom: 10px;
    line-height: 1.4; }

.o-assetModal__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.o-assetModal__section {
  border-bottom: 2px solid #ebebeb;
  padding: 20px; }
  .o-assetModal__section:last-child {
    border-bottom: 0; }

.o-assetModal__owner {
  display: flex;
  align-items: center; }
  .o-assetModal__owner-avatar {
    border-radius: 50%;
    margin-right: 10px; }

.o-assetModal__formGroupAssetName input {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem; }

.o-assetModal__loader-getImagem {
  position: block;
  margin-top: 20px;
  margin-bottom: 20px; }

.o-assetModal__loader-getOriginalImage.container {
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%; }

.o-assetModal__loader-getOriginalImage.loading {
  position: relative;
  top: 43%;
  left: 0;
  right: 0; }

.o-assetModal__loader-getOriginalImage.text {
  color: #FFF;
  position: relative;
  top: 50%;
  left: 0;
  right: 0; }

.o-assetModal__category {
  margin-top: 20px;
  margin-bottom: 10px; }
  .o-assetModal__category.field {
    margin-top: 5px;
    margin-bottom: 5px; }
  .o-assetModal__category.button {
    margin-top: 40px; }
  .o-assetModal__category.select {
    margin-top: 40px; }
  .o-assetModal__category.msg {
    margin-top: 40px; }
