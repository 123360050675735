.p-groupList__owner {
  display: flex;
  align-items: center;
  padding-right: 40px; }
  .p-groupList__owner-avatar {
    border-radius: 50%;
    margin-right: 10px; }

.p-groupList__name:hover {
  cursor: pointer; }
