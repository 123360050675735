.a-radio {
  cursor: pointer; }
  .a-radio__input {
    display: none; }
  .a-radio__radio {
    display: inline-block;
    border: 2px solid #e6e6e6;
    padding: 2px;
    border-radius: 50%;
    vertical-align: middle;
    transition: border-color 300ms; }
    .a-radio__radio:before {
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: background-color 300ms; }
    .a-radio__input:checked + .a-radio__radio {
      border-color: #4a90e2; }
      .a-radio__input:checked + .a-radio__radio:before {
        background-color: #4a90e2; }
