.m-assetItem {
  position: relative; }
  .m-assetItem__box {
    transition: box-shadow 300ms;
    transition: opacity 300ms; }
    .m-assetItem:hover .m-assetItem__box {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); }
    .m-assetItem__box.inactive {
      opacity: .7; }
  .m-assetItem__infos {
    display: flex;
    align-items: flex-end; }
  .m-assetItem__text {
    flex-grow: 1;
    flex-basis: 0%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .m-assetItem__icons {
    padding-left: 5px; }
  .m-assetItem__thumb {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    backface-visibility: hidden;
    text-align: center; }
    .m-assetItem.clickable .m-assetItem__thumb {
      cursor: pointer; }
    .m-assetItem__thumb-media {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      transition: transform 300ms, filter 300ms; }
      .m-assetItem:hover .m-assetItem__thumb-media {
        transform: translate(-50%, -50%) scale(1.1); }
      .m-assetItem__thumb-media.image {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%); }
      .m-assetItem__thumb-media.icon {
        color: #4a90e2;
        font-size: 50px;
        font-size: 5rem;
        width: 50px;
        height: 50px; }
      .m-assetItem__thumb-media.icon-image {
        color: #cccccc;
        font-size: 50px;
        font-size: 5rem;
        width: 50px;
        height: 50px; }
      .m-assetItem__thumb-media.text {
        color: #cccccc;
        font-size: 12px;
        font-size: 1.2rem;
        margin-top: 40px; }
      .m-assetItem__thumb-media.icon-pdf {
        color: #db4437; }
      .m-assetItem__box.inactive .m-assetItem__thumb-media {
        filter: blur(5px); }
    .m-assetItem__thumb-blank {
      width: 100%; }
  .m-assetItem__title {
    font-weight: 700;
    margin-bottom: 5px;
    transition: color 300ms;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .m-assetItem:hover .m-assetItem__title {
      color: #595959; }
    .m-assetItem.clickable .m-assetItem__title {
      cursor: pointer; }
  .m-assetItem__legend {
    font-size: 10px;
    font-size: 1rem; }
  .m-assetItem__icon {
    color: #a6a6a6; }
  .m-assetItem__remove {
    position: absolute;
    top: -15px;
    right: -15px; }
