.m-dropFiles {
  position: relative;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  height: 350px;
  border: 2px solid #e6e6e6;
  border-radius: 25px;
  background-color: #F7F7F7;
  transition: background-color 300ms, border-color 300ms; }
  .m-dropFiles.dragging {
    border-color: #4fc8d5;
    background-color: #e7f8f9; }
  .m-dropFiles__legend {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-size: 1.8rem;
    transition: color 300ms; }
    .m-dropFiles.dragging .m-dropFiles__legend {
      color: #4fc8d5; }
  .m-dropFiles__row {
    position: relative;
    z-index: 1;
    height: calc(100% - 50px);
    overflow-y: auto;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px; }
  .m-dropFiles__col {
    margin-bottom: 20px; }
  .m-dropFiles__actions {
    display: flex; }
    .m-dropFiles__actions-progressBar {
      flex: 1; }
