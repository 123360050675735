.a-squareLabel {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  color: #4fc8d5;
  border: 2px solid #4fc8d5;
  min-height: 30px;
  cursor: pointer;
  transition: background 300ms, color 300ms; }
  .a-squareLabel:hover {
    color: #2aa1ae; }
  .a-squareLabel.active, .a-squareLabel:hover {
    border-color: #2aa1ae; }
  .a-squareLabel.active {
    background-color: #4fc8d5;
    color: #FFFFFF; }
  .a-squareLabel__text {
    line-height: 1.4;
    margin-top: 5px;
    margin-bottom: 5px; }
  .a-squareLabel__badge {
    position: absolute;
    top: -10px;
    right: -10px; }
  .a-squareLabel__marginBadge {
    margin-right: 15px !important;
    margin-top: 10px !important; }
