.p-analytics__header {
  display: flex; }

.p-analytics__filterByUploadDate {
  margin-left: auto; }

.p-analytics__globalFormGroup {
  position: relative; }
  .p-analytics__globalFormGroup-formGroup {
    cursor: pointer; }
    .p-analytics__globalFormGroup-formGroup input {
      cursor: pointer;
      padding-right: 60px; }
  .p-analytics__globalFormGroup-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px; }

.p-analytics__dropdownFilterUploadDate {
  width: 510px; }

.p-analytics__sectionHorizontal {
  border-right: 2px solid #e6e6e6; }
  .p-analytics__sectionHorizontal:last-child {
    border-right: 0; }

.p-analytics__sectionVertical {
  border-bottom: 2px solid #e6e6e6; }
  .p-analytics__sectionVertical:last-child {
    border-bottom: 0; }

.p-analytics__typeLabel, .p-analytics__typeLabel--video, .p-analytics__typeLabel--audio, .p-analytics__typeLabel--image {
  display: inline-block;
  padding: 2.5px 5px;
  border: 1px solid #d1d1d1;
  color: #8c8c8c;
  border-radius: 6px; }
  .p-analytics__typeLabel--video {
    border-color: #3366CC;
    color: #3366CC; }
  .p-analytics__typeLabel--audio {
    border-color: #DC3912;
    color: #DC3912; }
  .p-analytics__typeLabel--image {
    border-color: #f90;
    color: #f90; }

.p-analytics__box--billing .p-analytics__box-col {
  display: flex;
  align-items: center;
  border-right: 2px solid #ebebeb;
  border-bottom: 2px solid #ebebeb; }
  @media (min-width: 1024px) {
    .p-analytics__box--billing .p-analytics__box-col {
      border-bottom: 0; } }
  .p-analytics__box--billing .p-analytics__box-col:nth-child(2n) {
    border-right: none; }
    @media (min-width: 1024px) {
      .p-analytics__box--billing .p-analytics__box-col:nth-child(2n) {
        border-right: 2px solid #ebebeb; } }
  .p-analytics__box--billing .p-analytics__box-col:last-child {
    border-right: none; }
  .p-analytics__box--billing .p-analytics__box-col:nth-last-child(1), .p-analytics__box--billing .p-analytics__box-col:nth-last-child(2) {
    border-bottom: 0; }
