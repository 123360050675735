.p-login__google-login-button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  color: gray;
  height: 40px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.p-login__google-login-button:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
  color: #666666; }

.p-login__google-logo {
  position: absolute;
  left: 8px;
  width: 18px;
  height: 18px; }
