.a-timestamp {
  transition: color 300ms;
  line-height: 1.6;
  cursor: pointer;
  border: 2px solid #ebebeb;
  border-radius: 14px;
  padding-left: 4px;
  padding-right: 4px; }
  .a-timestamp__current {
    border-color: #4fc8d5 !important;
    background-color: #4fc8d5;
    color: #FFFFFF; }
  .a-timestamp:hover {
    color: #595959; }
  .a-timestamp__time {
    display: inline-block;
    padding: 2.5px 10px;
    background-color: #4fc8d5;
    color: #FFF;
    border-radius: 10px;
    transition: background 300ms;
    margin: 2px 5px 2px -2px; }
    .a-timestamp:hover .a-timestamp__time {
      background-color: #5fcdd9; }
  .a-timestamp__time-current {
    background-color: #FFFFFF;
    color: #4fc8d5; }
    .a-timestamp:hover .a-timestamp__time-current {
      background-color: #FFFFFF; }
  .a-timestamp__text {
    line-height: 1.4; }
