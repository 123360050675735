.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }
  .row.noGutter {
    margin-left: 0;
    margin-right: 0; }
  .row.noWrap {
    flex-wrap: nowrap; }
  .row.start-xs {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .row.center-xs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .row.end-xs {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .row.top-xs {
    align-items: flex-start; }
  .row.middle-xs {
    align-items: center; }
  .row.bottom-xs {
    align-items: flex-end; }
  @media (min-width: 576px) {
    .row.start-sm {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      text-align: start; }
    .row.center-sm {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .row.end-sm {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      text-align: end; }
    .row.top-sm {
      align-items: flex-start; }
    .row.middle-sm {
      align-items: center; }
    .row.bottom-sm {
      align-items: flex-end; } }
  @media (min-width: 768px) {
    .row.start-md {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      text-align: start; }
    .row.center-md {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .row.end-md {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      text-align: end; }
    .row.top-md {
      align-items: flex-start; }
    .row.middle-md {
      align-items: center; }
    .row.bottom-md {
      align-items: flex-end; } }
  @media (min-width: 1024px) {
    .row.start-lg {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      text-align: start; }
    .row.center-lg {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .row.end-lg {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      text-align: end; }
    .row.top-lg {
      align-items: flex-start; }
    .row.middle-lg {
      align-items: center; }
    .row.bottom-lg {
      align-items: flex-end; } }
  @media (min-width: 1280px) {
    .row.start-xlg {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      text-align: start; }
    .row.center-xlg {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .row.end-xlg {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      text-align: end; }
    .row.top-xlg {
      align-items: flex-start; }
    .row.middle-xlg {
      align-items: center; }
    .row.bottom-xlg {
      align-items: flex-end; } }
