.m-dropdownContainer {
  position: relative;
  display: inline-block; }
  .m-dropdownContainer__target {
    display: inline-block;
    cursor: pointer; }
  .m-dropdownContainer__dropdown {
    z-index: 2;
    position: absolute;
    top: calc(100% + 10px);
    transform: scaleY(0);
    opacity: 0;
    transform-origin: top left;
    transition: transform 300ms cubic-bezier(0, 1, 0.5, 1), opacity 300ms; }
    .m-dropdownContainer__dropdown.show {
      transform: scaleY(1);
      opacity: 1; }
    .m-dropdownContainer__dropdown.right {
      right: 0; }
    .m-dropdownContainer__dropdown.left {
      left: 0; }
