.a-progressBar {
  width: 100%;
  height: 20px;
  border: 2px solid #e6e6e6;
  border-radius: 25px;
  background-color: #f8f8f8;
  overflow: hidden; }

@keyframes progress-striped {
  from {
    background-position: -26px 0; }
  to {
    background-position: 0 0; } }
  .a-progressBar__progress {
    display: block;
    height: 100%;
    background: repeating-linear-gradient(45deg, #34ce57, #34ce57 10px, #28a745 10px, #28a745 20px);
    background-size: 200%;
    transition: width 300ms ease-in;
    animation: progress-striped 2s linear infinite; }
