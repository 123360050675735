.m-modal {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.85);
  animation: showMaskModal 300ms; }
  .m-modal__navControls {
    padding-right: 10px;
    padding-left: 10px; }
  .m-modal__loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%; }
  .m-modal__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .m-modal__globalBox {
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px; }
  .m-modal__box {
    z-index: 1;
    position: relative;
    overflow: auto;
    max-height: 100%;
    animation: showBoxModal 300ms; }
  .m-modal__title {
    display: block;
    min-height: 80px;
    line-height: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #e6e6e6;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 700; }
  .m-modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1; }
  .m-modal__next {
    position: relative;
    float: right;
    z-index: 1;
    top: 50%; }
    .m-modal__next:after {
      position: absolute;
      content: '';
      top: -160px;
      bottom: -160px;
      right: -5px;
      left: -5px; }
    .m-modal__next:hover:after {
      z-index: -1;
      background: linear-gradient(transparent, rgba(128, 128, 128, 0.4), transparent); }
  .m-modal__back {
    position: relative;
    float: left;
    z-index: 1;
    top: 50%;
    transform: rotate(180deg); }
    .m-modal__back:after {
      position: absolute;
      content: '';
      top: -160px;
      bottom: -160px;
      right: -5px;
      left: -5px; }
    .m-modal__back:hover:after {
      z-index: -1;
      background: linear-gradient(transparent, rgba(128, 128, 128, 0.4), transparent); }
  .m-modal__fullHeight {
    height: 100%; }

@keyframes showMaskModal {
  from {
    background-color: transparent; }
  to {
    background-color: rgba(0, 0, 0, 0.85); } }

@keyframes showBoxModal {
  from {
    opacity: 0;
    transform: translateY(-100%); }
  to {
    opacity: 1;
    transform: translateY(0%); } }
