.a-pdfReader {
  position: relative;
  background-color: #525659;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-y: auto; }
  .a-pdfReader .page {
    border: 0 !important;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.3);
    margin: 0 auto 20px !important; }
