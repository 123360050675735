.m-breadCrumb {
  display: flex; }
  .m-breadCrumb__item {
    display: flex;
    align-items: center; }
    .m-breadCrumb__item:last-child {
      font-weight: bold; }
  .m-breadCrumb__link:hover {
    text-decoration: underline; }
  .m-breadCrumb__arrow {
    color: #d1d1d1; }
    .m-breadCrumb__item:last-child .m-breadCrumb__arrow {
      display: none; }
