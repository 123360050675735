.o-collectionDetails__header {
  background-color: #4a90e2; }
  .o-collectionDetails__header-title {
    font-size: 26px;
    font-size: 2.6rem;
    color: #FFF;
    font-weight: 700; }
  .o-collectionDetails__header p {
    color: #FFF; }

.o-collectionDetails__formGroupName input {
  font-weight: 700;
  font-size: 22px;
  font-size: 2.2rem; }
