@font-face {
  font-family: 'apollo-icons';
  src: url("./font/apollo-icons.eot?7476243");
  src: url("./font/apollo-icons.eot?7476243#iefix") format("embedded-opentype"), url("./font/apollo-icons.woff2?7476243") format("woff2"), url("./font/apollo-icons.woff?7476243") format("woff"), url("./font/apollo-icons.ttf?7476243") format("truetype"), url("./font/apollo-icons.svg?7476243#apollo-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  display: inline-block;
  font-family: "apollo-icons";
  font-style: initial; }
  .icon.small {
    font-size: 16px;
    font-size: 1.6rem;
    width: 16px;
    height: 16px; }
  .icon.medium {
    font-size: 20px;
    font-size: 2rem;
    width: 20px;
    height: 20px; }
  .icon.large {
    font-size: 26px;
    font-size: 2.6rem;
    width: 26px;
    height: 26px; }

.icon-bookmark:before {
  content: '\e800'; }

.icon-time:before {
  content: '\e801'; }

.icon-add:before {
  content: '\e802'; }

.icon-down:before {
  content: '\e803'; }

.icon-audio:before {
  content: '\e804'; }

.icon-grid:before {
  content: '\e805'; }

.icon-profile:before {
  content: '\e806'; }

.icon-announcement:before {
  content: '\e807'; }

.icon-clear:before {
  content: '\e808'; }

.icon-check:before {
  content: '\e809'; }

.icon-arrows:before {
  content: '\e80a'; }

.icon-calendar:before {
  content: '\e80b'; }

.icon-download:before {
  content: '\e80c'; }

.icon-copy:before {
  content: '\e80d'; }

.icon-heart:before {
  content: '\e80e'; }

.icon-next:before {
  content: '\e80f'; }

.icon-chart:before {
  content: '\e810'; }

.icon-menu:before {
  content: '\e811'; }

.icon-edit:before {
  content: '\e812'; }

.icon-more:before {
  content: '\e813'; }

.icon-list:before {
  content: '\e814'; }

.icon-group:before {
  content: '\e815'; }

.icon-right:before {
  content: '\e816'; }

.icon-upload:before {
  content: '\e817'; }

.icon-delete:before {
  content: '\e818'; }

.icon-dashboard:before {
  content: '\e819'; }

.icon-user:before {
  content: '\e81a'; }

.icon-image:before {
  content: '\e81b'; }

.icon-video:before {
  content: '\e81c'; }

.icon-settings:before {
  content: '\e81d'; }

.icon-share:before {
  content: '\e81e'; }

.icon-like:before {
  content: '\e81f'; }

.icon-stream:before {
  content: '\e820'; }

.icon-visibility:before {
  content: '\e821'; }

.icon-search:before {
  content: '\e822'; }

.icon-business:before {
  content: '\e823'; }

.icon-brain:before {
  content: '\e824'; }

.icon-google:before {
  content: '\e826'; }

.icon-loop:before {
  content: '\e827'; }

.icon-expand:before {
  content: '\e828'; }

.icon-facebook:before {
  content: '\e825'; }

.icon-twitter:before {
  content: '\e829'; }

.icon-linkedin:before {
  content: '\e82a'; }

.icon-logout:before {
  content: '\e82b'; }

.icon-right-1:before {
  content: '\e82c'; }

.icon-home:before {
  content: '\e82d'; }

.icon-squad:before {
  content: '\e82e'; }

.icon-file:before {
  content: '\e830'; }

.icon-pdf:before {
  content: '\e831'; }

.icon-volume-mute:before {
  content: '\e832'; }

.icon-volume-down:before {
  content: '\e833'; }

.icon-volume-up:before {
  content: '\e82f'; }

.icon-full-screen:before {
  content: '\e834'; }

.icon-full-screen-exit:before {
  content: '\e835'; }

.icon-pause:before {
  content: '\e836'; }

.icon-videostamp:before {
  content: '\e837'; }

.icon-sublevel:before {
  content: '\e838'; }

.icon-loupe:before {
  content: '\e83b'; }

.icon-zoom-out:before {
  content: '\e837'; }

.icon-zoom-in:before {
  content: '\e838'; }

.icon-undo:before {
  content: '\e83c'; }

.icon-restore-clock:before {
  content: '\e83d'; }

.icon-restore:before {
  content: '\e83e'; }

.icon-custom-fields:before {
  content: '\e83f'; }

.icon-replay:before {
  content: '\e843'; }

.icon-filter:before {
  content: '\e840'; }
