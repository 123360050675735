.m-titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #e6e6e6; }
  .m-titleSection__title {
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-weight: 700; }
  .m-titleSection__icon {
    margin-right: 10px; }
