.o-assets__col {
  flex-grow: 1;
  flex-basis: 240px; }
  @media (min-width: 500px) {
    .o-assets__col {
      max-width: 50%; } }
  @media (min-width: 740px) {
    .o-assets__col {
      max-width: 33.33333%; } }
  @media (min-width: 980px) {
    .o-assets__col {
      max-width: 25%; } }
  @media (min-width: 1220px) {
    .o-assets__col {
      max-width: 20%; } }
  @media (min-width: 1460px) {
    .o-assets__col {
      max-width: 16.66667%; } }
  @media (min-width: 1700px) {
    .o-assets__col {
      max-width: 14.28571%; } }
  @media (min-width: 1940px) {
    .o-assets__col {
      max-width: 12.5%; } }
  @media (min-width: 2180px) {
    .o-assets__col {
      max-width: 11.11111%; } }
  @media (min-width: 2420px) {
    .o-assets__col {
      max-width: 10%; } }
  @media (min-width: 2660px) {
    .o-assets__col {
      max-width: 9.09091%; } }
  @media (min-width: 2900px) {
    .o-assets__col {
      max-width: 8.33333%; } }
  @media (min-width: 3140px) {
    .o-assets__col {
      max-width: 7.69231%; } }
  @media (min-width: 3380px) {
    .o-assets__col {
      max-width: 7.14286%; } }
  @media (min-width: 3620px) {
    .o-assets__col {
      max-width: 6.66667%; } }
  @media (min-width: 3860px) {
    .o-assets__col {
      max-width: 6.25%; } }
  @media (min-width: 4100px) {
    .o-assets__col {
      max-width: 5.88235%; } }
  @media (min-width: 4340px) {
    .o-assets__col {
      max-width: 5.55556%; } }
  @media (min-width: 4580px) {
    .o-assets__col {
      max-width: 5.26316%; } }
  @media (min-width: 4820px) {
    .o-assets__col {
      max-width: 5%; } }
  @media (min-width: 5060px) {
    .o-assets__col {
      max-width: 4.7619%; } }

.o-assets__viewModeByList-thumb {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 60px;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer; }

.o-assets__viewModeByList-image2 {
  height: 300px;
  border-radius: 4px;
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 1; }

.o-assets__viewModeByList-media {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .o-assets__viewModeByList-media.icon {
    color: #4a90e2;
    font-size: 40px;
    font-size: 4rem;
    width: 40px;
    height: 40px; }
  .o-assets__viewModeByList-media.icon-pdf {
    color: #db4437; }
  .o-assets__viewModeByList-media.image {
    max-width: 100%;
    max-height: 100%; }
  .o-assets__viewModeByList-media .icon-image {
    color: #cccccc;
    font-size: 40px;
    font-size: 4rem;
    width: 40px;
    height: 40px; }
  .o-assets__viewModeByList-media .text {
    color: #cccccc;
    font-size: 9px;
    font-size: 0.9rem; }

.o-assets__viewModeByList-assetLegend {
  display: block;
  font-size: 10px;
  font-size: 1rem; }

.o-assets__viewModeByList-title {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer; }
