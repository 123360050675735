.q-loader {
  display: block;
  width: 26px;
  height: 26px;
  animation: rotator 1.4s linear infinite; }
  .q-loader.centralized {
    margin-left: auto;
    margin-right: auto; }
  .q-loader__path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    stroke-width: 6;
    stroke-linecap: round;
    transform-origin: center; }
    .q-loader.colorful .q-loader__path {
      stroke: #4fc8d5;
      animation: dash 1.4s ease-in-out infinite, colors 2.8s linear infinite; }
    .q-loader.negative .q-loader__path {
      stroke: #FFF;
      animation: dash 1.4s ease-in-out infinite; }

@keyframes colors {
  0% {
    stroke: #d32f2f; }
  49% {
    stroke: #d32f2f; }
  51% {
    stroke: #4fc8d5; }
  100% {
    stroke: #4fc8d5; } }

@keyframes rotator {
  0% {
    transform: rotate(0deg);
    opacity: 0; }
  15% {
    opacity: 1; }
  85% {
    opacity: 1; }
  100% {
    transform: rotate(270deg);
    opacity: 0; } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }
