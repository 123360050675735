.a-square {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%; }
  .a-square__item {
    position: absolute;
    cursor: pointer;
    box-sizing: initial;
    border: 2px solid #ffff00;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    z-index: 1;
    padding: 5px; }
  .a-square__animation {
    animation: featureItem 450ms linear; }

@keyframes featureItem {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2);
    background-color: #FFF; }
  100% {
    transform: scale(1); } }
