.a-key {
  cursor: pointer; }
  .a-key__input {
    display: none; }
  .a-key__check {
    display: inline-block;
    width: 48px;
    border: 2px solid #e6e6e6;
    padding: 2px;
    border-radius: 15px;
    background-color: #FFFFFF;
    transition: background-color 300ms, border-color 300ms;
    vertical-align: middle; }
    .a-key__check:before {
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #e6e6e6;
      transition: margin-left 300ms, background-color 300ms; }
    .a-key__input:checked + .a-key__check {
      background-color: #4a90e2;
      border-color: #4a90e2; }
      .a-key__input:checked + .a-key__check:before {
        margin-left: 20px;
        background-color: #FFF; }
