.m-image-viewer {
  position: relative;
  overflow: hidden; }
  .m-image-viewer:hover .m-image-viewer__controls {
    visibility: visible; }
  .m-image-viewer:hover .m-image-viewer__controls-zoom-in {
    visibility: visible; }
  .m-image-viewer__image {
    position: relative;
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
  .m-image-viewer__image-move {
    cursor: move; }
  .m-image-viewer__controls {
    position: absolute;
    bottom: 10px;
    right: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    flex-direction: column-reverse; }
  .m-image-viewer__controls-range {
    transform: rotate(-90deg);
    width: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer; }
  .m-image-viewer__controls-zoom-in {
    visibility: hidden;
    position: absolute;
    bottom: 10px;
    right: 10px; }
