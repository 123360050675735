.color-states-success {
  color: #28a745; }

.color-negative-type-1 {
  color: #FFF; }

.color-default-dark {
  color: #595959; }

.bg-default-2 {
  background-color: #e6e6e6; }

.float-xs-left {
  float: left; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left; } }

@media (min-width: 1280px) {
  .float-xlg-left {
    float: left; } }

.float-xs-right {
  float: right; }

@media (min-width: 576px) {
  .float-sm-right {
    float: right; } }

@media (min-width: 768px) {
  .float-md-right {
    float: right; } }

@media (min-width: 1024px) {
  .float-lg-right {
    float: right; } }

@media (min-width: 1280px) {
  .float-xlg-right {
    float: right; } }

.float-xs-none {
  float: none; }

@media (min-width: 576px) {
  .float-sm-none {
    float: none; } }

@media (min-width: 768px) {
  .float-md-none {
    float: none; } }

@media (min-width: 1024px) {
  .float-lg-none {
    float: none; } }

@media (min-width: 1280px) {
  .float-xlg-none {
    float: none; } }

.hidden-xs {
  display: none; }

@media (min-width: 576px) {
  .hidden-sm {
    display: none; } }

@media (min-width: 768px) {
  .hidden-md {
    display: none; } }

@media (min-width: 1024px) {
  .hidden-lg {
    display: none; } }

@media (min-width: 1280px) {
  .hidden-xlg {
    display: none; } }

.block-xs {
  display: block; }

@media (min-width: 576px) {
  .block-sm {
    display: block; } }

@media (min-width: 768px) {
  .block-md {
    display: block; } }

@media (min-width: 1024px) {
  .block-lg {
    display: block; } }

@media (min-width: 1280px) {
  .block-xlg {
    display: block; } }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.p-xs-0 {
  padding: 0 !important; }

.p-xs-0.5 {
  padding: 2.5px !important; }

.p-xs-1 {
  padding: 5px !important; }

.p-xs-2 {
  padding: 10px !important; }

.p-xs-3 {
  padding: 20px !important; }

.p-xs-4 {
  padding: 40px !important; }

.p-xs-5 {
  padding: 80px !important; }

.p-xs-6 {
  padding: 160px !important; }

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .p-sm-0.5 {
    padding: 2.5px !important; } }

@media (min-width: 576px) {
  .p-sm-1 {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .p-sm-2 {
    padding: 10px !important; } }

@media (min-width: 576px) {
  .p-sm-3 {
    padding: 20px !important; } }

@media (min-width: 576px) {
  .p-sm-4 {
    padding: 40px !important; } }

@media (min-width: 576px) {
  .p-sm-5 {
    padding: 80px !important; } }

@media (min-width: 576px) {
  .p-sm-6 {
    padding: 160px !important; } }

@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .p-md-0.5 {
    padding: 2.5px !important; } }

@media (min-width: 768px) {
  .p-md-1 {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .p-md-2 {
    padding: 10px !important; } }

@media (min-width: 768px) {
  .p-md-3 {
    padding: 20px !important; } }

@media (min-width: 768px) {
  .p-md-4 {
    padding: 40px !important; } }

@media (min-width: 768px) {
  .p-md-5 {
    padding: 80px !important; } }

@media (min-width: 768px) {
  .p-md-6 {
    padding: 160px !important; } }

@media (min-width: 1024px) {
  .p-lg-0 {
    padding: 0 !important; } }

@media (min-width: 1024px) {
  .p-lg-0.5 {
    padding: 2.5px !important; } }

@media (min-width: 1024px) {
  .p-lg-1 {
    padding: 5px !important; } }

@media (min-width: 1024px) {
  .p-lg-2 {
    padding: 10px !important; } }

@media (min-width: 1024px) {
  .p-lg-3 {
    padding: 20px !important; } }

@media (min-width: 1024px) {
  .p-lg-4 {
    padding: 40px !important; } }

@media (min-width: 1024px) {
  .p-lg-5 {
    padding: 80px !important; } }

@media (min-width: 1024px) {
  .p-lg-6 {
    padding: 160px !important; } }

@media (min-width: 1280px) {
  .p-xlg-0 {
    padding: 0 !important; } }

@media (min-width: 1280px) {
  .p-xlg-0.5 {
    padding: 2.5px !important; } }

@media (min-width: 1280px) {
  .p-xlg-1 {
    padding: 5px !important; } }

@media (min-width: 1280px) {
  .p-xlg-2 {
    padding: 10px !important; } }

@media (min-width: 1280px) {
  .p-xlg-3 {
    padding: 20px !important; } }

@media (min-width: 1280px) {
  .p-xlg-4 {
    padding: 40px !important; } }

@media (min-width: 1280px) {
  .p-xlg-5 {
    padding: 80px !important; } }

@media (min-width: 1280px) {
  .p-xlg-6 {
    padding: 160px !important; } }

.px-xs-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-xs-0.5 {
  padding-left: 2.5px !important;
  padding-right: 2.5px !important; }

.px-xs-1 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-2 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.px-xs-3 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.px-xs-4 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.px-xs-5 {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.px-xs-6 {
  padding-left: 160px !important;
  padding-right: 160px !important; }

@media (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .px-sm-0.5 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important; } }

@media (min-width: 576px) {
  .px-sm-1 {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .px-sm-2 {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media (min-width: 576px) {
  .px-sm-3 {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 576px) {
  .px-sm-4 {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 576px) {
  .px-sm-5 {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 576px) {
  .px-sm-6 {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .px-md-0.5 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important; } }

@media (min-width: 768px) {
  .px-md-1 {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .px-md-2 {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media (min-width: 768px) {
  .px-md-3 {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 768px) {
  .px-md-4 {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 768px) {
  .px-md-5 {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 768px) {
  .px-md-6 {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media (min-width: 1024px) {
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1024px) {
  .px-lg-0.5 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important; } }

@media (min-width: 1024px) {
  .px-lg-1 {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1024px) {
  .px-lg-2 {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media (min-width: 1024px) {
  .px-lg-3 {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 1024px) {
  .px-lg-4 {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 1024px) {
  .px-lg-5 {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 1024px) {
  .px-lg-6 {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media (min-width: 1280px) {
  .px-xlg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1280px) {
  .px-xlg-0.5 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important; } }

@media (min-width: 1280px) {
  .px-xlg-1 {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1280px) {
  .px-xlg-2 {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media (min-width: 1280px) {
  .px-xlg-3 {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 1280px) {
  .px-xlg-4 {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 1280px) {
  .px-xlg-5 {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 1280px) {
  .px-xlg-6 {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

.py-xs-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-xs-0.5 {
  padding-top: 2.5px !important;
  padding-bottom: 2.5px !important; }

.py-xs-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.py-xs-3 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.py-xs-4 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.py-xs-5 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.py-xs-6 {
  padding-top: 160px !important;
  padding-bottom: 160px !important; }

@media (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .py-sm-0.5 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important; } }

@media (min-width: 576px) {
  .py-sm-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .py-sm-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; } }

@media (min-width: 576px) {
  .py-sm-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 576px) {
  .py-sm-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 576px) {
  .py-sm-5 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 576px) {
  .py-sm-6 {
    padding-top: 160px !important;
    padding-bottom: 160px !important; } }

@media (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .py-md-0.5 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important; } }

@media (min-width: 768px) {
  .py-md-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .py-md-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; } }

@media (min-width: 768px) {
  .py-md-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 768px) {
  .py-md-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 768px) {
  .py-md-5 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 768px) {
  .py-md-6 {
    padding-top: 160px !important;
    padding-bottom: 160px !important; } }

@media (min-width: 1024px) {
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1024px) {
  .py-lg-0.5 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important; } }

@media (min-width: 1024px) {
  .py-lg-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1024px) {
  .py-lg-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; } }

@media (min-width: 1024px) {
  .py-lg-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 1024px) {
  .py-lg-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 1024px) {
  .py-lg-5 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 1024px) {
  .py-lg-6 {
    padding-top: 160px !important;
    padding-bottom: 160px !important; } }

@media (min-width: 1280px) {
  .py-xlg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1280px) {
  .py-xlg-0.5 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important; } }

@media (min-width: 1280px) {
  .py-xlg-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1280px) {
  .py-xlg-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; } }

@media (min-width: 1280px) {
  .py-xlg-3 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 1280px) {
  .py-xlg-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 1280px) {
  .py-xlg-5 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 1280px) {
  .py-xlg-6 {
    padding-top: 160px !important;
    padding-bottom: 160px !important; } }

.pt-xs-0 {
  padding-top: 0 !important; }

.pt-xs-0.5 {
  padding-top: 2.5px !important; }

.pt-xs-1 {
  padding-top: 5px !important; }

.pt-xs-2 {
  padding-top: 10px !important; }

.pt-xs-3 {
  padding-top: 20px !important; }

.pt-xs-4 {
  padding-top: 40px !important; }

.pt-xs-5 {
  padding-top: 80px !important; }

.pt-xs-6 {
  padding-top: 160px !important; }

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .pt-sm-0.5 {
    padding-top: 2.5px !important; } }

@media (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 10px !important; } }

@media (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 20px !important; } }

@media (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 40px !important; } }

@media (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 80px !important; } }

@media (min-width: 576px) {
  .pt-sm-6 {
    padding-top: 160px !important; } }

@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .pt-md-0.5 {
    padding-top: 2.5px !important; } }

@media (min-width: 768px) {
  .pt-md-1 {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .pt-md-2 {
    padding-top: 10px !important; } }

@media (min-width: 768px) {
  .pt-md-3 {
    padding-top: 20px !important; } }

@media (min-width: 768px) {
  .pt-md-4 {
    padding-top: 40px !important; } }

@media (min-width: 768px) {
  .pt-md-5 {
    padding-top: 80px !important; } }

@media (min-width: 768px) {
  .pt-md-6 {
    padding-top: 160px !important; } }

@media (min-width: 1024px) {
  .pt-lg-0 {
    padding-top: 0 !important; } }

@media (min-width: 1024px) {
  .pt-lg-0.5 {
    padding-top: 2.5px !important; } }

@media (min-width: 1024px) {
  .pt-lg-1 {
    padding-top: 5px !important; } }

@media (min-width: 1024px) {
  .pt-lg-2 {
    padding-top: 10px !important; } }

@media (min-width: 1024px) {
  .pt-lg-3 {
    padding-top: 20px !important; } }

@media (min-width: 1024px) {
  .pt-lg-4 {
    padding-top: 40px !important; } }

@media (min-width: 1024px) {
  .pt-lg-5 {
    padding-top: 80px !important; } }

@media (min-width: 1024px) {
  .pt-lg-6 {
    padding-top: 160px !important; } }

@media (min-width: 1280px) {
  .pt-xlg-0 {
    padding-top: 0 !important; } }

@media (min-width: 1280px) {
  .pt-xlg-0.5 {
    padding-top: 2.5px !important; } }

@media (min-width: 1280px) {
  .pt-xlg-1 {
    padding-top: 5px !important; } }

@media (min-width: 1280px) {
  .pt-xlg-2 {
    padding-top: 10px !important; } }

@media (min-width: 1280px) {
  .pt-xlg-3 {
    padding-top: 20px !important; } }

@media (min-width: 1280px) {
  .pt-xlg-4 {
    padding-top: 40px !important; } }

@media (min-width: 1280px) {
  .pt-xlg-5 {
    padding-top: 80px !important; } }

@media (min-width: 1280px) {
  .pt-xlg-6 {
    padding-top: 160px !important; } }

.pr-xs-0 {
  padding-right: 0 !important; }

.pr-xs-0.5 {
  padding-right: 2.5px !important; }

.pr-xs-1 {
  padding-right: 5px !important; }

.pr-xs-2 {
  padding-right: 10px !important; }

.pr-xs-3 {
  padding-right: 20px !important; }

.pr-xs-4 {
  padding-right: 40px !important; }

.pr-xs-5 {
  padding-right: 80px !important; }

.pr-xs-6 {
  padding-right: 160px !important; }

@media (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .pr-sm-0.5 {
    padding-right: 2.5px !important; } }

@media (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 10px !important; } }

@media (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 20px !important; } }

@media (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 40px !important; } }

@media (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 80px !important; } }

@media (min-width: 576px) {
  .pr-sm-6 {
    padding-right: 160px !important; } }

@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .pr-md-0.5 {
    padding-right: 2.5px !important; } }

@media (min-width: 768px) {
  .pr-md-1 {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .pr-md-2 {
    padding-right: 10px !important; } }

@media (min-width: 768px) {
  .pr-md-3 {
    padding-right: 20px !important; } }

@media (min-width: 768px) {
  .pr-md-4 {
    padding-right: 40px !important; } }

@media (min-width: 768px) {
  .pr-md-5 {
    padding-right: 80px !important; } }

@media (min-width: 768px) {
  .pr-md-6 {
    padding-right: 160px !important; } }

@media (min-width: 1024px) {
  .pr-lg-0 {
    padding-right: 0 !important; } }

@media (min-width: 1024px) {
  .pr-lg-0.5 {
    padding-right: 2.5px !important; } }

@media (min-width: 1024px) {
  .pr-lg-1 {
    padding-right: 5px !important; } }

@media (min-width: 1024px) {
  .pr-lg-2 {
    padding-right: 10px !important; } }

@media (min-width: 1024px) {
  .pr-lg-3 {
    padding-right: 20px !important; } }

@media (min-width: 1024px) {
  .pr-lg-4 {
    padding-right: 40px !important; } }

@media (min-width: 1024px) {
  .pr-lg-5 {
    padding-right: 80px !important; } }

@media (min-width: 1024px) {
  .pr-lg-6 {
    padding-right: 160px !important; } }

@media (min-width: 1280px) {
  .pr-xlg-0 {
    padding-right: 0 !important; } }

@media (min-width: 1280px) {
  .pr-xlg-0.5 {
    padding-right: 2.5px !important; } }

@media (min-width: 1280px) {
  .pr-xlg-1 {
    padding-right: 5px !important; } }

@media (min-width: 1280px) {
  .pr-xlg-2 {
    padding-right: 10px !important; } }

@media (min-width: 1280px) {
  .pr-xlg-3 {
    padding-right: 20px !important; } }

@media (min-width: 1280px) {
  .pr-xlg-4 {
    padding-right: 40px !important; } }

@media (min-width: 1280px) {
  .pr-xlg-5 {
    padding-right: 80px !important; } }

@media (min-width: 1280px) {
  .pr-xlg-6 {
    padding-right: 160px !important; } }

.pb-xs-0 {
  padding-bottom: 0 !important; }

.pb-xs-0.5 {
  padding-bottom: 2.5px !important; }

.pb-xs-1 {
  padding-bottom: 5px !important; }

.pb-xs-2 {
  padding-bottom: 10px !important; }

.pb-xs-3 {
  padding-bottom: 20px !important; }

.pb-xs-4 {
  padding-bottom: 40px !important; }

.pb-xs-5 {
  padding-bottom: 80px !important; }

.pb-xs-6 {
  padding-bottom: 160px !important; }

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .pb-sm-0.5 {
    padding-bottom: 2.5px !important; } }

@media (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 10px !important; } }

@media (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 20px !important; } }

@media (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 40px !important; } }

@media (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 80px !important; } }

@media (min-width: 576px) {
  .pb-sm-6 {
    padding-bottom: 160px !important; } }

@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .pb-md-0.5 {
    padding-bottom: 2.5px !important; } }

@media (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 10px !important; } }

@media (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 20px !important; } }

@media (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 40px !important; } }

@media (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 80px !important; } }

@media (min-width: 768px) {
  .pb-md-6 {
    padding-bottom: 160px !important; } }

@media (min-width: 1024px) {
  .pb-lg-0 {
    padding-bottom: 0 !important; } }

@media (min-width: 1024px) {
  .pb-lg-0.5 {
    padding-bottom: 2.5px !important; } }

@media (min-width: 1024px) {
  .pb-lg-1 {
    padding-bottom: 5px !important; } }

@media (min-width: 1024px) {
  .pb-lg-2 {
    padding-bottom: 10px !important; } }

@media (min-width: 1024px) {
  .pb-lg-3 {
    padding-bottom: 20px !important; } }

@media (min-width: 1024px) {
  .pb-lg-4 {
    padding-bottom: 40px !important; } }

@media (min-width: 1024px) {
  .pb-lg-5 {
    padding-bottom: 80px !important; } }

@media (min-width: 1024px) {
  .pb-lg-6 {
    padding-bottom: 160px !important; } }

@media (min-width: 1280px) {
  .pb-xlg-0 {
    padding-bottom: 0 !important; } }

@media (min-width: 1280px) {
  .pb-xlg-0.5 {
    padding-bottom: 2.5px !important; } }

@media (min-width: 1280px) {
  .pb-xlg-1 {
    padding-bottom: 5px !important; } }

@media (min-width: 1280px) {
  .pb-xlg-2 {
    padding-bottom: 10px !important; } }

@media (min-width: 1280px) {
  .pb-xlg-3 {
    padding-bottom: 20px !important; } }

@media (min-width: 1280px) {
  .pb-xlg-4 {
    padding-bottom: 40px !important; } }

@media (min-width: 1280px) {
  .pb-xlg-5 {
    padding-bottom: 80px !important; } }

@media (min-width: 1280px) {
  .pb-xlg-6 {
    padding-bottom: 160px !important; } }

.pl-xs-0 {
  padding-left: 0 !important; }

.pl-xs-0.5 {
  padding-left: 2.5px !important; }

.pl-xs-1 {
  padding-left: 5px !important; }

.pl-xs-2 {
  padding-left: 10px !important; }

.pl-xs-3 {
  padding-left: 20px !important; }

.pl-xs-4 {
  padding-left: 40px !important; }

.pl-xs-5 {
  padding-left: 80px !important; }

.pl-xs-6 {
  padding-left: 160px !important; }

@media (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .pl-sm-0.5 {
    padding-left: 2.5px !important; } }

@media (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 10px !important; } }

@media (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 20px !important; } }

@media (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 40px !important; } }

@media (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 80px !important; } }

@media (min-width: 576px) {
  .pl-sm-6 {
    padding-left: 160px !important; } }

@media (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .pl-md-0.5 {
    padding-left: 2.5px !important; } }

@media (min-width: 768px) {
  .pl-md-1 {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .pl-md-2 {
    padding-left: 10px !important; } }

@media (min-width: 768px) {
  .pl-md-3 {
    padding-left: 20px !important; } }

@media (min-width: 768px) {
  .pl-md-4 {
    padding-left: 40px !important; } }

@media (min-width: 768px) {
  .pl-md-5 {
    padding-left: 80px !important; } }

@media (min-width: 768px) {
  .pl-md-6 {
    padding-left: 160px !important; } }

@media (min-width: 1024px) {
  .pl-lg-0 {
    padding-left: 0 !important; } }

@media (min-width: 1024px) {
  .pl-lg-0.5 {
    padding-left: 2.5px !important; } }

@media (min-width: 1024px) {
  .pl-lg-1 {
    padding-left: 5px !important; } }

@media (min-width: 1024px) {
  .pl-lg-2 {
    padding-left: 10px !important; } }

@media (min-width: 1024px) {
  .pl-lg-3 {
    padding-left: 20px !important; } }

@media (min-width: 1024px) {
  .pl-lg-4 {
    padding-left: 40px !important; } }

@media (min-width: 1024px) {
  .pl-lg-5 {
    padding-left: 80px !important; } }

@media (min-width: 1024px) {
  .pl-lg-6 {
    padding-left: 160px !important; } }

@media (min-width: 1280px) {
  .pl-xlg-0 {
    padding-left: 0 !important; } }

@media (min-width: 1280px) {
  .pl-xlg-0.5 {
    padding-left: 2.5px !important; } }

@media (min-width: 1280px) {
  .pl-xlg-1 {
    padding-left: 5px !important; } }

@media (min-width: 1280px) {
  .pl-xlg-2 {
    padding-left: 10px !important; } }

@media (min-width: 1280px) {
  .pl-xlg-3 {
    padding-left: 20px !important; } }

@media (min-width: 1280px) {
  .pl-xlg-4 {
    padding-left: 40px !important; } }

@media (min-width: 1280px) {
  .pl-xlg-5 {
    padding-left: 80px !important; } }

@media (min-width: 1280px) {
  .pl-xlg-6 {
    padding-left: 160px !important; } }

.m-xs-0 {
  margin: 0 !important; }

.m-xs-0.5 {
  margin: 2.5px !important; }

.m-xs-1 {
  margin: 5px !important; }

.m-xs-2 {
  margin: 10px !important; }

.m-xs-3 {
  margin: 20px !important; }

.m-xs-4 {
  margin: 40px !important; }

.m-xs-5 {
  margin: 80px !important; }

.m-xs-6 {
  margin: 160px !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .m-sm-0.5 {
    margin: 2.5px !important; } }

@media (min-width: 576px) {
  .m-sm-1 {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .m-sm-2 {
    margin: 10px !important; } }

@media (min-width: 576px) {
  .m-sm-3 {
    margin: 20px !important; } }

@media (min-width: 576px) {
  .m-sm-4 {
    margin: 40px !important; } }

@media (min-width: 576px) {
  .m-sm-5 {
    margin: 80px !important; } }

@media (min-width: 576px) {
  .m-sm-6 {
    margin: 160px !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .m-md-0.5 {
    margin: 2.5px !important; } }

@media (min-width: 768px) {
  .m-md-1 {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .m-md-2 {
    margin: 10px !important; } }

@media (min-width: 768px) {
  .m-md-3 {
    margin: 20px !important; } }

@media (min-width: 768px) {
  .m-md-4 {
    margin: 40px !important; } }

@media (min-width: 768px) {
  .m-md-5 {
    margin: 80px !important; } }

@media (min-width: 768px) {
  .m-md-6 {
    margin: 160px !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; } }

@media (min-width: 1024px) {
  .m-lg-0.5 {
    margin: 2.5px !important; } }

@media (min-width: 1024px) {
  .m-lg-1 {
    margin: 5px !important; } }

@media (min-width: 1024px) {
  .m-lg-2 {
    margin: 10px !important; } }

@media (min-width: 1024px) {
  .m-lg-3 {
    margin: 20px !important; } }

@media (min-width: 1024px) {
  .m-lg-4 {
    margin: 40px !important; } }

@media (min-width: 1024px) {
  .m-lg-5 {
    margin: 80px !important; } }

@media (min-width: 1024px) {
  .m-lg-6 {
    margin: 160px !important; } }

@media (min-width: 1280px) {
  .m-xlg-0 {
    margin: 0 !important; } }

@media (min-width: 1280px) {
  .m-xlg-0.5 {
    margin: 2.5px !important; } }

@media (min-width: 1280px) {
  .m-xlg-1 {
    margin: 5px !important; } }

@media (min-width: 1280px) {
  .m-xlg-2 {
    margin: 10px !important; } }

@media (min-width: 1280px) {
  .m-xlg-3 {
    margin: 20px !important; } }

@media (min-width: 1280px) {
  .m-xlg-4 {
    margin: 40px !important; } }

@media (min-width: 1280px) {
  .m-xlg-5 {
    margin: 80px !important; } }

@media (min-width: 1280px) {
  .m-xlg-6 {
    margin: 160px !important; } }

.mx-xs-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-xs-0.5 {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important; }

.mx-xs-1 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-2 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.mx-xs-3 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mx-xs-4 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.mx-xs-5 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.mx-xs-6 {
  margin-left: 160px !important;
  margin-right: 160px !important; }

@media (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .mx-sm-0.5 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important; } }

@media (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 10px !important;
    margin-right: 10px !important; } }

@media (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 576px) {
  .mx-sm-6 {
    margin-left: 160px !important;
    margin-right: 160px !important; } }

@media (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .mx-md-0.5 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important; } }

@media (min-width: 768px) {
  .mx-md-1 {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .mx-md-2 {
    margin-left: 10px !important;
    margin-right: 10px !important; } }

@media (min-width: 768px) {
  .mx-md-3 {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 768px) {
  .mx-md-4 {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 768px) {
  .mx-md-5 {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 768px) {
  .mx-md-6 {
    margin-left: 160px !important;
    margin-right: 160px !important; } }

@media (min-width: 1024px) {
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1024px) {
  .mx-lg-0.5 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important; } }

@media (min-width: 1024px) {
  .mx-lg-1 {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1024px) {
  .mx-lg-2 {
    margin-left: 10px !important;
    margin-right: 10px !important; } }

@media (min-width: 1024px) {
  .mx-lg-3 {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 1024px) {
  .mx-lg-4 {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 1024px) {
  .mx-lg-5 {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 1024px) {
  .mx-lg-6 {
    margin-left: 160px !important;
    margin-right: 160px !important; } }

@media (min-width: 1280px) {
  .mx-xlg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1280px) {
  .mx-xlg-0.5 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important; } }

@media (min-width: 1280px) {
  .mx-xlg-1 {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1280px) {
  .mx-xlg-2 {
    margin-left: 10px !important;
    margin-right: 10px !important; } }

@media (min-width: 1280px) {
  .mx-xlg-3 {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 1280px) {
  .mx-xlg-4 {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 1280px) {
  .mx-xlg-5 {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 1280px) {
  .mx-xlg-6 {
    margin-left: 160px !important;
    margin-right: 160px !important; } }

.my-xs-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-xs-0.5 {
  margin-top: 2.5px !important;
  margin-bottom: 2.5px !important; }

.my-xs-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.my-xs-3 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.my-xs-4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.my-xs-5 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.my-xs-6 {
  margin-top: 160px !important;
  margin-bottom: 160px !important; }

@media (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .my-sm-0.5 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important; } }

@media (min-width: 576px) {
  .my-sm-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .my-sm-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; } }

@media (min-width: 576px) {
  .my-sm-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 576px) {
  .my-sm-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 576px) {
  .my-sm-5 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 576px) {
  .my-sm-6 {
    margin-top: 160px !important;
    margin-bottom: 160px !important; } }

@media (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .my-md-0.5 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important; } }

@media (min-width: 768px) {
  .my-md-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .my-md-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; } }

@media (min-width: 768px) {
  .my-md-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 768px) {
  .my-md-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 768px) {
  .my-md-5 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 768px) {
  .my-md-6 {
    margin-top: 160px !important;
    margin-bottom: 160px !important; } }

@media (min-width: 1024px) {
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1024px) {
  .my-lg-0.5 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important; } }

@media (min-width: 1024px) {
  .my-lg-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1024px) {
  .my-lg-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; } }

@media (min-width: 1024px) {
  .my-lg-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 1024px) {
  .my-lg-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 1024px) {
  .my-lg-5 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 1024px) {
  .my-lg-6 {
    margin-top: 160px !important;
    margin-bottom: 160px !important; } }

@media (min-width: 1280px) {
  .my-xlg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1280px) {
  .my-xlg-0.5 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important; } }

@media (min-width: 1280px) {
  .my-xlg-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1280px) {
  .my-xlg-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important; } }

@media (min-width: 1280px) {
  .my-xlg-3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 1280px) {
  .my-xlg-4 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 1280px) {
  .my-xlg-5 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 1280px) {
  .my-xlg-6 {
    margin-top: 160px !important;
    margin-bottom: 160px !important; } }

.mt-xs-0 {
  margin-top: 0 !important; }

.mt-xs-0.5 {
  margin-top: 2.5px !important; }

.mt-xs-1 {
  margin-top: 5px !important; }

.mt-xs-2 {
  margin-top: 10px !important; }

.mt-xs-3 {
  margin-top: 20px !important; }

.mt-xs-4 {
  margin-top: 40px !important; }

.mt-xs-5 {
  margin-top: 80px !important; }

.mt-xs-6 {
  margin-top: 160px !important; }

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .mt-sm-0.5 {
    margin-top: 2.5px !important; } }

@media (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 10px !important; } }

@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 20px !important; } }

@media (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 40px !important; } }

@media (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 80px !important; } }

@media (min-width: 576px) {
  .mt-sm-6 {
    margin-top: 160px !important; } }

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .mt-md-0.5 {
    margin-top: 2.5px !important; } }

@media (min-width: 768px) {
  .mt-md-1 {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .mt-md-2 {
    margin-top: 10px !important; } }

@media (min-width: 768px) {
  .mt-md-3 {
    margin-top: 20px !important; } }

@media (min-width: 768px) {
  .mt-md-4 {
    margin-top: 40px !important; } }

@media (min-width: 768px) {
  .mt-md-5 {
    margin-top: 80px !important; } }

@media (min-width: 768px) {
  .mt-md-6 {
    margin-top: 160px !important; } }

@media (min-width: 1024px) {
  .mt-lg-0 {
    margin-top: 0 !important; } }

@media (min-width: 1024px) {
  .mt-lg-0.5 {
    margin-top: 2.5px !important; } }

@media (min-width: 1024px) {
  .mt-lg-1 {
    margin-top: 5px !important; } }

@media (min-width: 1024px) {
  .mt-lg-2 {
    margin-top: 10px !important; } }

@media (min-width: 1024px) {
  .mt-lg-3 {
    margin-top: 20px !important; } }

@media (min-width: 1024px) {
  .mt-lg-4 {
    margin-top: 40px !important; } }

@media (min-width: 1024px) {
  .mt-lg-5 {
    margin-top: 80px !important; } }

@media (min-width: 1024px) {
  .mt-lg-6 {
    margin-top: 160px !important; } }

@media (min-width: 1280px) {
  .mt-xlg-0 {
    margin-top: 0 !important; } }

@media (min-width: 1280px) {
  .mt-xlg-0.5 {
    margin-top: 2.5px !important; } }

@media (min-width: 1280px) {
  .mt-xlg-1 {
    margin-top: 5px !important; } }

@media (min-width: 1280px) {
  .mt-xlg-2 {
    margin-top: 10px !important; } }

@media (min-width: 1280px) {
  .mt-xlg-3 {
    margin-top: 20px !important; } }

@media (min-width: 1280px) {
  .mt-xlg-4 {
    margin-top: 40px !important; } }

@media (min-width: 1280px) {
  .mt-xlg-5 {
    margin-top: 80px !important; } }

@media (min-width: 1280px) {
  .mt-xlg-6 {
    margin-top: 160px !important; } }

.mr-xs-0 {
  margin-right: 0 !important; }

.mr-xs-0.5 {
  margin-right: 2.5px !important; }

.mr-xs-1 {
  margin-right: 5px !important; }

.mr-xs-2 {
  margin-right: 10px !important; }

.mr-xs-3 {
  margin-right: 20px !important; }

.mr-xs-4 {
  margin-right: 40px !important; }

.mr-xs-5 {
  margin-right: 80px !important; }

.mr-xs-6 {
  margin-right: 160px !important; }

@media (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .mr-sm-0.5 {
    margin-right: 2.5px !important; } }

@media (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 10px !important; } }

@media (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 20px !important; } }

@media (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 40px !important; } }

@media (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 80px !important; } }

@media (min-width: 576px) {
  .mr-sm-6 {
    margin-right: 160px !important; } }

@media (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .mr-md-0.5 {
    margin-right: 2.5px !important; } }

@media (min-width: 768px) {
  .mr-md-1 {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .mr-md-2 {
    margin-right: 10px !important; } }

@media (min-width: 768px) {
  .mr-md-3 {
    margin-right: 20px !important; } }

@media (min-width: 768px) {
  .mr-md-4 {
    margin-right: 40px !important; } }

@media (min-width: 768px) {
  .mr-md-5 {
    margin-right: 80px !important; } }

@media (min-width: 768px) {
  .mr-md-6 {
    margin-right: 160px !important; } }

@media (min-width: 1024px) {
  .mr-lg-0 {
    margin-right: 0 !important; } }

@media (min-width: 1024px) {
  .mr-lg-0.5 {
    margin-right: 2.5px !important; } }

@media (min-width: 1024px) {
  .mr-lg-1 {
    margin-right: 5px !important; } }

@media (min-width: 1024px) {
  .mr-lg-2 {
    margin-right: 10px !important; } }

@media (min-width: 1024px) {
  .mr-lg-3 {
    margin-right: 20px !important; } }

@media (min-width: 1024px) {
  .mr-lg-4 {
    margin-right: 40px !important; } }

@media (min-width: 1024px) {
  .mr-lg-5 {
    margin-right: 80px !important; } }

@media (min-width: 1024px) {
  .mr-lg-6 {
    margin-right: 160px !important; } }

@media (min-width: 1280px) {
  .mr-xlg-0 {
    margin-right: 0 !important; } }

@media (min-width: 1280px) {
  .mr-xlg-0.5 {
    margin-right: 2.5px !important; } }

@media (min-width: 1280px) {
  .mr-xlg-1 {
    margin-right: 5px !important; } }

@media (min-width: 1280px) {
  .mr-xlg-2 {
    margin-right: 10px !important; } }

@media (min-width: 1280px) {
  .mr-xlg-3 {
    margin-right: 20px !important; } }

@media (min-width: 1280px) {
  .mr-xlg-4 {
    margin-right: 40px !important; } }

@media (min-width: 1280px) {
  .mr-xlg-5 {
    margin-right: 80px !important; } }

@media (min-width: 1280px) {
  .mr-xlg-6 {
    margin-right: 160px !important; } }

.mb-xs-0 {
  margin-bottom: 0 !important; }

.mb-xs-0.5 {
  margin-bottom: 2.5px !important; }

.mb-xs-1 {
  margin-bottom: 5px !important; }

.mb-xs-2 {
  margin-bottom: 10px !important; }

.mb-xs-3 {
  margin-bottom: 20px !important; }

.mb-xs-4 {
  margin-bottom: 40px !important; }

.mb-xs-5 {
  margin-bottom: 80px !important; }

.mb-xs-6 {
  margin-bottom: 160px !important; }

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .mb-sm-0.5 {
    margin-bottom: 2.5px !important; } }

@media (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 10px !important; } }

@media (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 20px !important; } }

@media (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 40px !important; } }

@media (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 80px !important; } }

@media (min-width: 576px) {
  .mb-sm-6 {
    margin-bottom: 160px !important; } }

@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .mb-md-0.5 {
    margin-bottom: 2.5px !important; } }

@media (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 10px !important; } }

@media (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 20px !important; } }

@media (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 40px !important; } }

@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 80px !important; } }

@media (min-width: 768px) {
  .mb-md-6 {
    margin-bottom: 160px !important; } }

@media (min-width: 1024px) {
  .mb-lg-0 {
    margin-bottom: 0 !important; } }

@media (min-width: 1024px) {
  .mb-lg-0.5 {
    margin-bottom: 2.5px !important; } }

@media (min-width: 1024px) {
  .mb-lg-1 {
    margin-bottom: 5px !important; } }

@media (min-width: 1024px) {
  .mb-lg-2 {
    margin-bottom: 10px !important; } }

@media (min-width: 1024px) {
  .mb-lg-3 {
    margin-bottom: 20px !important; } }

@media (min-width: 1024px) {
  .mb-lg-4 {
    margin-bottom: 40px !important; } }

@media (min-width: 1024px) {
  .mb-lg-5 {
    margin-bottom: 80px !important; } }

@media (min-width: 1024px) {
  .mb-lg-6 {
    margin-bottom: 160px !important; } }

@media (min-width: 1280px) {
  .mb-xlg-0 {
    margin-bottom: 0 !important; } }

@media (min-width: 1280px) {
  .mb-xlg-0.5 {
    margin-bottom: 2.5px !important; } }

@media (min-width: 1280px) {
  .mb-xlg-1 {
    margin-bottom: 5px !important; } }

@media (min-width: 1280px) {
  .mb-xlg-2 {
    margin-bottom: 10px !important; } }

@media (min-width: 1280px) {
  .mb-xlg-3 {
    margin-bottom: 20px !important; } }

@media (min-width: 1280px) {
  .mb-xlg-4 {
    margin-bottom: 40px !important; } }

@media (min-width: 1280px) {
  .mb-xlg-5 {
    margin-bottom: 80px !important; } }

@media (min-width: 1280px) {
  .mb-xlg-6 {
    margin-bottom: 160px !important; } }

.ml-xs-0 {
  margin-left: 0 !important; }

.ml-xs-0.5 {
  margin-left: 2.5px !important; }

.ml-xs-1 {
  margin-left: 5px !important; }

.ml-xs-2 {
  margin-left: 10px !important; }

.ml-xs-3 {
  margin-left: 20px !important; }

.ml-xs-4 {
  margin-left: 40px !important; }

.ml-xs-5 {
  margin-left: 80px !important; }

.ml-xs-6 {
  margin-left: 160px !important; }

@media (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .ml-sm-0.5 {
    margin-left: 2.5px !important; } }

@media (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 10px !important; } }

@media (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 20px !important; } }

@media (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 40px !important; } }

@media (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 80px !important; } }

@media (min-width: 576px) {
  .ml-sm-6 {
    margin-left: 160px !important; } }

@media (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .ml-md-0.5 {
    margin-left: 2.5px !important; } }

@media (min-width: 768px) {
  .ml-md-1 {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .ml-md-2 {
    margin-left: 10px !important; } }

@media (min-width: 768px) {
  .ml-md-3 {
    margin-left: 20px !important; } }

@media (min-width: 768px) {
  .ml-md-4 {
    margin-left: 40px !important; } }

@media (min-width: 768px) {
  .ml-md-5 {
    margin-left: 80px !important; } }

@media (min-width: 768px) {
  .ml-md-6 {
    margin-left: 160px !important; } }

@media (min-width: 1024px) {
  .ml-lg-0 {
    margin-left: 0 !important; } }

@media (min-width: 1024px) {
  .ml-lg-0.5 {
    margin-left: 2.5px !important; } }

@media (min-width: 1024px) {
  .ml-lg-1 {
    margin-left: 5px !important; } }

@media (min-width: 1024px) {
  .ml-lg-2 {
    margin-left: 10px !important; } }

@media (min-width: 1024px) {
  .ml-lg-3 {
    margin-left: 20px !important; } }

@media (min-width: 1024px) {
  .ml-lg-4 {
    margin-left: 40px !important; } }

@media (min-width: 1024px) {
  .ml-lg-5 {
    margin-left: 80px !important; } }

@media (min-width: 1024px) {
  .ml-lg-6 {
    margin-left: 160px !important; } }

@media (min-width: 1280px) {
  .ml-xlg-0 {
    margin-left: 0 !important; } }

@media (min-width: 1280px) {
  .ml-xlg-0.5 {
    margin-left: 2.5px !important; } }

@media (min-width: 1280px) {
  .ml-xlg-1 {
    margin-left: 5px !important; } }

@media (min-width: 1280px) {
  .ml-xlg-2 {
    margin-left: 10px !important; } }

@media (min-width: 1280px) {
  .ml-xlg-3 {
    margin-left: 20px !important; } }

@media (min-width: 1280px) {
  .ml-xlg-4 {
    margin-left: 40px !important; } }

@media (min-width: 1280px) {
  .ml-xlg-5 {
    margin-left: 80px !important; } }

@media (min-width: 1280px) {
  .ml-xlg-6 {
    margin-left: 160px !important; } }

.w-xs-100 {
  width: 100%; }

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100%; } }

@media (min-width: 768px) {
  .w-md-100 {
    width: 100%; } }

@media (min-width: 1024px) {
  .w-lg-100 {
    width: 100%; } }

@media (min-width: 1280px) {
  .w-xlg-100 {
    width: 100%; } }

.v-align-middle {
  vertical-align: middle; }

.text-xs-center {
  text-align: center; }

@media (min-width: 576px) {
  .text-sm-center {
    text-align: center; } }

@media (min-width: 768px) {
  .text-md-center {
    text-align: center; } }

@media (min-width: 1024px) {
  .text-lg-center {
    text-align: center; } }

@media (min-width: 1280px) {
  .text-xlg-center {
    text-align: center; } }

.text-xs-right {
  text-align: right; }

@media (min-width: 576px) {
  .text-sm-right {
    text-align: right; } }

@media (min-width: 768px) {
  .text-md-right {
    text-align: right; } }

@media (min-width: 1024px) {
  .text-lg-right {
    text-align: right; } }

@media (min-width: 1280px) {
  .text-xlg-right {
    text-align: right; } }

@keyframes fadeIn {
  from {
    visibility: hidden;
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    visibility: hidden; } }

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0); }
  20%,
  60%,
  80% {
    transform: translate3d(-10px, 0, 0); }
  40%,
  80% {
    transform: translate3d(10px, 0, 0); } }

@keyframes loop {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.heading-1 {
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: 700; }

.heading-2 {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 700; }

.heading-3 {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 700; }

.lineHeightParagraph {
  line-height: 1.4; }

.uppercase {
  text-transform: uppercase; }

.italic {
  font-style: italic; }

.radiusCircle {
  border-radius: 50%; }

.cursorPointer {
  cursor: pointer; }

.arrayInfo {
  font-weight: 700; }
  .arrayInfo a {
    color: #4a90e2; }
    .arrayInfo a:hover {
      text-decoration: underline; }
  .arrayInfo:after {
    content: ', '; }
  .arrayInfo:last-child:after {
    content: ''; }

.flex {
  display: flex; }

.alignVerticalCenter {
  align-items: center; }

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, main, nav, section {
  display: block; }

a {
  color: inherit;
  text-decoration: none; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, textarea, select, button {
  font-size: inherit;
  font-family: inherit; }

button {
  background: transparent; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: normal; }

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%; }

body {
  font-size: 14px;
  font-size: 1.4rem;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #FFFFFF;
  color: #8c8c8c;
  min-width: 320px; }
  @media (min-width: 576px) {
    body {
      min-width: 576px; } }
  @media (min-width: 768px) {
    body {
      min-width: 768px; } }
  @media (min-width: 1024px) {
    body {
      min-width: 1024px; } }
  @media (min-width: 1280px) {
    body {
      min-width: 1280px; } }
