.a-button {
  display: inline-block;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  border: 2px solid transparent;
  transition: background 300ms, border 300ms; }
  .a-button-center {
    display: flex;
    align-items: center;
    justify-content: center; }
  .a-button.noPadding {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .a-button:disabled {
    opacity: .5;
    cursor: initial; }
  .a-button__icon {
    transition: color 300ms;
    vertical-align: middle; }
    .a-button__icon.loop {
      animation: loop 600ms linear infinite; }
  .a-button__inputFile {
    display: none; }
  .a-button.small {
    height: 30px;
    font-size: 12px;
    font-size: 1.2rem; }
    .a-button.small .a-button__icon {
      margin-top: -4px; }
  .a-button.medium {
    height: 40px; }
  .a-button.large {
    height: 50px; }
  .a-button.default.small {
    padding: 0 10px; }
  .a-button.default.medium {
    padding: 0 20px; }
  .a-button.default.large {
    padding: 0 20px; }
  .a-button.fluid {
    width: 100%; }
  .a-button.min.small {
    width: 30px;
    min-width: 30px; }
  .a-button.min.medium {
    width: 40px;
    min-width: 40px; }
  .a-button.min.large {
    width: 50px;
    min-width: 50px; }
  .a-button.primary.solid {
    background-color: #4fc8d5;
    color: #FFFFFF; }
    .a-button.primary.solid:hover {
      background-color: #5fcdd9; }
    .a-button.primary.solid:focus {
      background-color: #70d2dd; }
  .a-button.primary.ghost, .primary.a-button.outline {
    color: #4fc8d5; }
    .a-button.primary.ghost:hover, .primary.a-button.outline:hover {
      color: #68d0db; }
    .a-button.primary.ghost:focus, .primary.a-button.outline:focus {
      color: #80d7e1; }
  .a-button.primary.outline {
    border-color: #4fc8d5; }
    .a-button.primary.outline:hover {
      border-color: #78d5df; }
    .a-button.primary.outline:focus {
      border-color: #8ddbe4; }
  .a-button.light {
    color: gray; }
    .a-button.light .a-button__icon {
      color: #a6a6a6; }
    .a-button.light:hover {
      color: #666666; }
      .a-button.light:hover .a-button__icon {
        color: #8c8c8c; }
    .a-button.light:focus {
      color: #4d4d4d; }
      .a-button.light:focus .a-button__icon {
        color: #737373; }
    .a-button.light.solid {
      background-color: #e6e6e6; }
      .a-button.light.solid:hover {
        background-color: #d7d7d7; }
      .a-button.light.solid:focus {
        background-color: #c7c7c7; }
    .a-button.light.outline {
      border-color: #cccccc; }
      .a-button.light.outline:hover {
        border-color: #ababab; }
      .a-button.light.outline:focus {
        border-color: #a1a1a1; }
  .a-button.disabled.ghost, .disabled.a-button.primary.outline, .disabled.a-button.negative.outline {
    color: #b3b3b3; }
    .a-button.disabled.ghost .a-button__icon, .disabled.a-button.primary.outline .a-button__icon, .disabled.a-button.negative.outline .a-button__icon {
      color: #cdcdcd; }
    .a-button.disabled.ghost:hover, .disabled.a-button.primary.outline:hover, .disabled.a-button.negative.outline:hover {
      color: #9f9f9f; }
      .a-button.disabled.ghost:hover .a-button__icon, .disabled.a-button.primary.outline:hover .a-button__icon, .disabled.a-button.negative.outline:hover .a-button__icon {
        color: #b3b3b3; }
    .a-button.disabled.ghost:focus, .disabled.a-button.primary.outline:focus, .disabled.a-button.negative.outline:focus {
      color: #949494; }
      .a-button.disabled.ghost:focus .a-button__icon, .disabled.a-button.primary.outline:focus .a-button__icon, .disabled.a-button.negative.outline:focus .a-button__icon {
        color: #9f9f9f; }
  .a-button.negative.solid {
    background-color: #FFF;
    color: #4a90e2; }
    .a-button.negative.solid:hover {
      background-color: rgba(255, 255, 255, 0.8); }
    .a-button.negative.solid:focus {
      background-color: rgba(255, 255, 255, 0.7); }
  .a-button.negative.outline {
    color: #FFF;
    border-color: #FFF; }
    .a-button.negative.outline:hover {
      color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.65); }
    .a-button.negative.outline:focus {
      color: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.4); }
  .a-button.negative.ghost, .negative.a-button.outline {
    color: #FFF; }
    .a-button.negative.ghost:hover, .negative.a-button.outline:hover {
      color: rgba(255, 255, 255, 0.75); }
    .a-button.negative.ghost:focus, .negative.a-button.outline:focus {
      color: rgba(255, 255, 255, 0.5); }
  .a-button.danger.solid {
    background-color: #dc3545;
    color: #FFFFFF; }
    .a-button.danger.solid:hover {
      background-color: #e4606d; }
    .a-button.danger.solid:focus {
      background-color: #eb8c95; }
  .a-button.google.outline {
    color: #db4437;
    border-color: #db4437; }
    .a-button.google.outline:hover {
      color: #e1645a;
      border-color: #e78178; }
    .a-button.google.outline:focus {
      color: #e7857c;
      border-color: #eea9a3; }
  .a-button.google.ghost, .google.a-button.primary.outline, .google.a-button.negative.outline {
    color: #db4437; }
    .a-button.google.ghost:hover, .google.a-button.primary.outline:hover, .google.a-button.negative.outline:hover {
      color: #e1645a; }
    .a-button.google.ghost:focus, .google.a-button.primary.outline:focus, .google.a-button.negative.outline:focus {
      color: #e7857c; }
  .a-button.facebook.solid {
    background-color: #4267b2;
    color: #FFF; }
    .a-button.facebook.solid:hover {
      background-color: #4c71bd; }
    .a-button.facebook.solid:focus {
      background-color: #5b7dc2; }
  .a-button.twitter.solid {
    background-color: #1da1f2;
    color: #FFF; }
    .a-button.twitter.solid:hover {
      background-color: #30a9f3; }
    .a-button.twitter.solid:focus {
      background-color: #44b1f4; }
  .a-button.linkedin.solid {
    background-color: #0077B5;
    color: #FFF; }
    .a-button.linkedin.solid:hover {
      background-color: #0084c9; }
    .a-button.linkedin.solid:focus {
      background-color: #0092de; }
