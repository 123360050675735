.t-checkin {
  display: block;
  min-height: 100vh;
  background-color: #e6e6e6; }
  .t-checkin__globalBox {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; }
  .t-checkin__bg {
    background-color: #000;
    width: auto;
    height: auto;
    min-height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    .t-checkin__bg img {
      opacity: .4; }
  .t-checkin__header {
    text-align: center;
    background-color: #F7F7F7;
    border-bottom: 2px solid #e6e6e6; }
