.q-badge {
  border-radius: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid #d32f2f;
  padding: 2.5px;
  color: #FFFFFF;
  font-size: 10px;
  font-size: 1rem;
  background-color: #d32f2f;
  display: flex;
  align-items: center;
  justify-content: center; }
